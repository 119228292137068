const template = `
  mutation OrderCheckout(
	$amount: Int!,
	$type: OrderCheckoutType!,
	$typeParams: OrderCheckoutTypeParams!,
    $method: OrderCheckoutMethod!,
	$methodParams: OrderCheckoutMethodParams,
    $buyerInfo: OrderCheckoutBuyerInfo
  ) {
	orderCheckout(
	  amount: $amount,
	  type: $type,
	  typeParams: $typeParams,
	  method: $method,
	  methodParams: $methodParams
      buyerInfo: $buyerInfo
	)
  }
`

export default async function orderCheckout({ amount, type, typeParams, method, methodParams, buyerInfo }) {
  await request(template, { amount, type, typeParams, method, methodParams, buyerInfo })
}
