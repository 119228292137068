import stylistServicesRequest from 'request/hooks/stylist-services'

const state = { serviceDic: [] }

const initData = {
  stylistServices: [],
  refreshStylistServices: null
}

export default function useStylistService(stylistUserId) {
  // eslint-disable-next-line no-unused-vars
  const { data, mutate } = useSWR('/hooks/stylistService', uuid)

  const refreshStylistServices = async() => {
    state.serviceDic[stylistUserId] = null
    await fetchStylistServices()
  }

  const fetchStylistServices = async() => {
    if (state.serviceDic[stylistUserId]) return
    const input = { stylistUserId, queryType: 'stylistService', isAdmin: true }
    const stylistServices = await stylistServicesRequest(input)
    state.serviceDic[stylistUserId] = { stylistServices, refreshStylistServices }
    mutate()
  }

  useEffect(() => {
    if (!stylistUserId) return
    fetchStylistServices()
  }, [stylistUserId])

  return state.serviceDic[stylistUserId] || initData
}
