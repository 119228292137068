const template = `
  query KeywordSearch($input: KeywordSearchInput!) {
    keywordSearch(input: $input) {
      hairBrands {
        id
        name
        url
      }
    }
  }
`

export default async function hairBrands(keyword, offset, limit) {
  const input = {
    keyword: keyword || '',
    type: 'hairBrand',
    paginationInput: { limit, offset }
  }
  const { keywordSearch } = await request(template, { input })
  return keywordSearch.hairBrands
}
