const template = `
  query BookingServices($input: BookingServicesInput!) {
    bookingServices(input: $input) {
      stylistServices {
        id
        service {
          id
          name
          category
        }
        minPrice
        maxPrice
        serviceTime
        description
        hairBrands {
          id
          name
          url
        }
        minPayment
        prepayAmount
      }
    }
  }
`

export default async function stylistServices(input) {
  const { bookingServices } = await request(template, { input })
  return bookingServices.stylistServices
}
