import checkoutRequest from 'request/common/order-checkout'
import checkoutByVisitorRequest from 'request/user-booking/checkout-by-visitor'
import usePayByPrime from 'hooks/payment-method/pay-by-prime'

export default function useCheckout({ input, byVisitor, onSuccess, onAtm, onZerocard }) {
  const t = useI18n()
  const { fetchPrime, paymentUrlCallback } = usePayByPrime()
  const onPaymentUrl = ({ paymentUrl, method }) => {
    if (!paymentUrl) return $alert('交易失敗', 'error')
    if (method === 'zerocard') {
      onZerocard()
      window.location = paymentUrl
      return
    }
    paymentUrlCallback({ method, paymentUrl })
  }

  return async() => {
    const { amount } = input
    if (!amount) input.method = 'atm'
    if (!validateInput({ method: input.method, t, amount })) return

    const result = await fetchPrime({ method: input.method, amount })
    if (result?.prime) {
      input.method = result.method
      input.methodParams.prime = result.prime
    }

    try {
      const request = byVisitor ? checkoutByVisitorRequest : checkoutRequest
      await request(input)
      onSuccess()
    } catch (error) {
      switch (error.code) {
        case 'payment.remitBankAccount':
          return onAtm()
        case 'payment.redirectTocallbackUrl':
          return onPaymentUrl({
            paymentUrl: error.params.paymentUrl,
            method: input.method
          })
        default:
          throw error
      }
    }
  }
}

const validateInput = ({ amount, method, t }) => {
  if (!amount) return true
  if (method) return true
  $alert(t('page:common.needMethod'), 'error')
  return false
}