const template = `
  query KeywordSearch($input: KeywordSearchInput!) {
    keywordSearch(input: $input) {
      studioProducts {
        id
        name
        cost
        costPerUnit
        originalPrice
        price
        inventory
        notes
        hairBrand {
          id
          name
          url
        }
        costType
        type
        categoryName
        categoryType
        minInventory
        restockInventory
        _minInventory
        _restockInventory
        exhibitInventory
        unit
        isOpenable
        isNeedWeight
        isNeedCheck
        isOfficialStock
        checkCategory
      }
    }
  }
`

export default async function studioProducts({ studioId, keyword, offset, limit, type, isCheckInventory, isShort, isNeedWeight }) {
  const input = {
    keyword: keyword || '',
    type: 'studioProduct',
    paginationInput: { limit, offset },
    params: {
      studioId,
      type,
      isCheckInventory,
      isShort,
      isNeedWeight
    }
  }
  const { keywordSearch } = await request(template, { input })
  return keywordSearch.studioProducts
}
