
export default function usePayByPrime() {
  const paymentUrlCallback = ({ method, paymentUrl }) => {
    if (method === 'linePay') return redirectToLinePay(paymentUrl)
    if (method === 'plusPay') return redirectToPlusPay(paymentUrl)
  }
  const fetchPrime = ({ method, amount }) => {
    if (method === 'googlePay') return requestGooglePayPrime(amount)
    if (method === 'applePay') return requestApplePayPrime(amount)
    if (method === 'linePay') return requestLinePayPrime(amount)
    if (method === 'plusPay') return requestPlusPayPrime(amount)
  }
  return { fetchPrime, paymentUrlCallback }
}

const requestGooglePayPrime = async amount => {
  const paymentRequest = {
    allowedNetworks: ['AMEX', 'JCB', 'MASTERCARD', 'VISA'],
    price: `${amount}`,
    currency: 'TWD'
  }
  const getPrimeFromTappay = () => new Promise ((resolve, reject) => TPDirect.googlePay.setupPaymentRequest(
    paymentRequest, (err, result) => {
      if (result.canUseGooglePay) resolve()
      $alert('Google Pay is not supported!', 'error')
      reject()
    }))
    .then(() => new Promise((resolve, reject) => TPDirect.googlePay.getPrime(async(err, prime) => {
      if (err) {
        $alert(err, 'error')
        reject(err)
      }
      if (!prime) {
        $alert('Unknown error', 'error')
        reject()
      }
      resolve({ prime, method: 'mobilePay' })
    })))
  return getPrimeFromTappay()
}

const requestApplePayPrime = async amount => {
  const paymentRequest = {
    supportedNetworks: ['AMEX', 'JCB', 'MASTERCARD', 'VISA'],
    supportedMethods: ['apple_pay'],
    displayItems: [{
      amount: {
        currency: 'TWD',
        value: `${amount}`
      }
    }],
    total: {
      label: 'StyleMap',
      amount: {
        currency: 'TWD',
        value: `${amount}`
      }
    }
  }

  const getPrimeFromTappay = () => new Promise ((resolve, reject) => {
    TPDirect.paymentRequestApi.setupPaymentRequest(paymentRequest)
    TPDirect.paymentRequestApi.getPrime(async result => {
      if (result.status !== 0) {
        $alert(result.errMsg, 'error')
        reject(result)
      }
      resolve({ prime: result.prime, method: 'mobilePay' })
    })
  })
  return getPrimeFromTappay()
}

const requestLinePayPrime = () => new Promise ((resolve, reject) => TPDirect.linePay.getPrime(result => {
  if (result.status !== 0) {
    $alert(result.errMsg, 'error')
    reject(result)
  }
  resolve({ prime: result.prime, method: 'linePay' })
}))
const redirectToLinePay = paymentUrl => TPDirect.redirect(paymentUrl)

const requestPlusPayPrime = () => new Promise ((resolve, reject) => TPDirect.plusPay.getPrime((error, result) => {
  if (error) {
    $alert(error, 'error')
    reject(error)
  }
  if (result.status !== 0) {
    $alert(result.errMsg, 'error')
    reject(result)
  }
  resolve({ prime: result.prime, method: 'plusPay' })
}))
const redirectToPlusPay = paymentUrl => TPDirect.plusPay.pay(paymentUrl)
