const template = `
  mutation CheckoutBookingByVisitor(
    $amount: Int!, 
    $typeParams: OrderCheckoutTypeParams!,
    $method: OrderCheckoutMethod!,
    $methodParams: OrderCheckoutMethodParams
  ) {
    checkoutBookingByVisitor(
      amount: $amount,
      typeParams: $typeParams,
      method: $method,
      methodParams: $methodParams
    )
  }
`

export default async function checkoutBookingByVisitor({ amount, typeParams, method, methodParams }) {
  await request(template, { amount, typeParams, method, methodParams })
}
